import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import Button from "components/Button"
import ContentBlock from "components/ContentBlock"
import GifVideo from "components/GifVideo"

const AnimatedCards = ({ title, richCopy, cards, cta, slug }) => (
  <div
    id={slug}
    css={theme.expandQueries({ padding: ["48px 25px", "30px 25px"] })}
  >
    <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
      <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

      <ContentBlock
        content={richCopy}
        css={theme.expandQueries({
          marginTop: [30, 20],
        })}
      />
    </div>

    <div
      css={{
        display: "grid",
        gridTemplateColumns: `repeat(${cards.length}, 1fr)`,
        justifyContent: "center",
        maxWidth: 1228,
        margin: "50px auto 60px",
        gap: 20,
        [theme.mq.tablet]: {
          marginTop: 40,
          gridTemplateColumns: "repeat(2, minmax(auto, 292px))",
        },
        [theme.mq.mobilePortrait]: {
          marginTop: 30,
          marginBottom: 40,
          gridTemplateColumns: "minmax(auto, 292px)",
        },
      }}
    >
      {cards.map(({ __key, title, video }) => (
        <div
          key={__key}
          css={{
            background: theme.colors.floatingContainerBackground,
            color: theme.colors.secondaryText,
            boxShadow: theme.shadows.tight,
            padding: 15,
            borderRadius: 4,
          }}
        >
          <GifVideo
            src={video.asset.url}
            css={{ maxWidth: "100%", padding: "15px 30px 30px" }}
          />
          <h3 css={{ ...theme.p3, fontWeight: 400, textAlign: "center" }}>
            {title}
          </h3>
        </div>
      ))}
    </div>

    {cta && (
      <div
        css={theme.expandQueries({
          textAlign: "center",
          marginTop: [60, 30],
        })}
      >
        <Button href={cta.url}>{cta.title}</Button>
      </div>
    )}
  </div>
)

export default AnimatedCards

AnimatedCards.propTypes = {
  title: PropTypes.string.isRequired,
  richCopy: PropTypes.array.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      __key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      video: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string,
}

export const query = graphql`
  fragment AnimatedCardsBlock on SanityAnimatedCards {
    title
    richCopy: _rawCopy
    cards {
      __key: _key
      title
      video {
        asset {
          url
        }
      }
    }
    cta {
      ...Link
    }
  }
`
