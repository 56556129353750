import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { ArcherContainer, ArcherElement } from "react-archer"
import theme from "styles/theme"
import ShadowPop from "components/ShadowPop"
import useMediaQuery from "hooks/useMediaQuery"

const Flowchart = ({ steps }) => {
  const mobile = useMediaQuery(theme.mq.mobile)

  return (
    <ShadowPop
      css={{
        background: theme.colors.extendedUI.offsetWhite,
        color: theme.colors.extendedUI.grey3,
        padding: "90px 60px",
        maxWidth: 1228,
        margin: "50px auto",
        transform: "none", // transforming this causes issues with Archer arrows
        [theme.mq.mobile]: {
          maxWidth: 500,
          padding: 25,
        },
      }}
    >
      <ArcherContainer
        strokeColor={theme.colors.extendedUI.grey3}
        strokeWidth={2}
        arrowLength={4}
        arrowThickness={6}
        offset={10}
      >
        <div
          css={{
            display: "grid",
            gridTemplateColumns: `repeat(3, 1fr)`,
            justifyContent: "center",
            alignItems: "stretch",
            gap: "100px 50px",
            textAlign: "center",
            [theme.mq.mobile]: {
              gridTemplateColumns: `1fr`,
              gap: 50,
            },
          }}
        >
          {steps.map(({ _key, label, description, color }, index) => (
            <ArcherElement
              key={_key}
              id={_key}
              relations={buildArcherTargets({
                position: index + 1,
                grid: !mobile,
                steps,
              })}
            >
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  order: index === 3 ? 6 : index === 5 ? 4 : index + 1,
                  [theme.mq.mobile]: {
                    order: "unset",
                  },
                }}
              >
                <div
                  css={{
                    ...theme.p3,
                    padding: "7px 20px",
                    borderRadius: 4,
                    boxShadow: theme.shadows.shallow,
                    background: color,
                    [theme.mq.mobile]: {
                      borderRadius: 2,
                    },
                  }}
                >
                  {label}
                </div>
                <div
                  css={{
                    ...theme.h3,
                    flex: "1 0 auto",
                    padding: 22,
                    borderRadius: 4,
                    boxShadow: theme.shadows.shallow,
                    background: color,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    [theme.mq.mobile]: {
                      borderRadius: 2,
                    },
                  }}
                >
                  {description}
                </div>
              </div>
            </ArcherElement>
          ))}
        </div>
      </ArcherContainer>
    </ShadowPop>
  )
}

export default Flowchart

Flowchart.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

const buildArcherTargets = ({
  position,
  steps,
  grid = true,
  columnCount = 3,
}) => {
  if (!grid && position === steps.length) return undefined

  return [
    grid
      ? {
          sourceAnchor:
            position === columnCount * 2
              ? "top"
              : position === columnCount
              ? "bottom"
              : position > columnCount
              ? "left"
              : "right",
          targetAnchor:
            position === columnCount * 2
              ? "bottom"
              : position === columnCount
              ? "top"
              : position > columnCount
              ? "right"
              : "left",
          targetId: (position === steps.length ? steps[0] : steps[position])
            ._key,
        }
      : {
          sourceAnchor: "bottom",
          targetAnchor: "top",
          targetId: steps[position]._key,
        },
  ]
}

export const query = graphql`
  fragment Flowchart on SanityFlowchartInfographic {
    _key
    _type
    steps {
      _key
      label
      description
      color
    }
  }
`
