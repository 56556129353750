import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import ContentBlock from "components/ContentBlock"

const TextColumns = ({ title, copy, columns, slug }) => (
  <div
    id={slug}
    css={{
      maxWidth: 1228 + 50,
      margin: "50px auto",
      padding: "0 25px",
      "& + &": {
        marginTop: 100,
        [theme.mq.mobile]: {
          marginTop: 50,
        },
      },
    }}
  >
    {title && (
      <h2
        css={{
          ...theme.h2,
          paddingLeft: "0.45em",
          borderLeft: `4px solid ${theme.colors.accentColor}`,
          position: "relative",
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: 8,
            height: 1,
            left: 0,
            background: theme.colors.accentColor,
          },
          ":before": { top: 0 },
          ":after": { bottom: 0 },
          [theme.mq.mobile]: {
            borderLeftWidth: 2,
            ":before, :after": {
              width: 4,
            },
          },
        }}
      >
        {title}
      </h2>
    )}

    {copy && (
      <p
        css={{
          ...theme.p2,
          maxWidth: title ? "36em" : "35em",
          paddingLeft: title ? "1.09em" : 0,
          color: theme.colors.secondaryText,
          margin: "0.675em 0 0 0",
          [theme.mq.mobile]: {
            paddingLeft: 0,
          },
        }}
      >
        {copy}
      </p>
    )}

    <div
      css={{
        marginTop: 50,
        display: "grid",
        gridTemplateColumns: `repeat(${
          columns.length % 3 === 0 ? 3 : 2
        }, minmax(auto, 28em))`,
        gap: "30px 50px",
        paddingLeft: "1.09em",
        [theme.mq.mobile]: {
          paddingLeft: 0,
        },
        [theme.mq.smallMobile]: {
          gridTemplateColumns: "1fr",
          gap: 20,
          maxWidth: 350,
          margin: "30px auto",
        },
      }}
    >
      {columns.map(({ _key, title, copy }) => {
        return (
          <div
            key={_key}
            css={{
              color: theme.colors.secondaryText,
            }}
          >
            <div css={{}}>
              <h4 css={{ fontSize: "inherit", fontWeight: 500 }}>{title}</h4>
              <ContentBlock content={copy} css={{ ...theme.p3 }} />
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default TextColumns

TextColumns.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      copy: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment TextColumnsBlock on SanityTextColumns {
    title
    copy
    columns {
      _key
      title
      copy: _rawCopy
    }
  }
`
