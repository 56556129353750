import React from "react"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import theme from "styles/theme"

const ShadowPop = ({ children, className, ...rest }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })

  return (
    <motion.div ref={ref} {...rest}>
      <div
        css={[
          theme.expandFull({
            boxShadow: theme.shadows.tight,
            background: theme.colors.floatingContainerBackground,
            borderRadius: [4, 3, 2],
            transform: "scale(0.95) translateY(15px)",
            transition: "transform 500ms ease-out, box-shadow 500ms ease-out",
            position: "relative",
            margin: "0 auto",
            zIndex: 2,
            ":after": {
              content: "''",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              borderRadius: [4, 3, 2],
              boxShadow: theme.shadows.blur,
              transition: "opacity 500ms ease-out",
              pointerEvents: "none",
              zIndex: 1,
              opacity: 0,
            },
          }),
          inView && {
            transform: "scale(1) translateY(0)",
            boxShadow: "none",
            ":after": {
              opacity: 1,
            },
          },
        ]}
        className={className}
      >
        {children}
      </div>
    </motion.div>
  )
}

export default ShadowPop

ShadowPop.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
