import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import ShadowPop from "components/ShadowPop"
import theme from "styles/theme"

const LogoSalad = ({ title, image, slug }) => (
  <section
    id={slug}
    css={{ padding: "100px 25px", [theme.mq.mobile]: { padding: "50px 25px" } }}
  >
    <h2
      css={{
        ...theme.h1,
        textAlign: "center",
        marginBottom: 60,
        [theme.mq.mobile]: { marginBottom: 30 },
      }}
    >
      {title}
    </h2>
    <ShadowPop
      css={{
        maxWidth: 1020,
        padding: "50px 90px",
        [theme.mq.mobile]: { padding: 30 },
      }}
    >
      <SanityImage
        {...image}
        width={840}
        sizes="(max-width: 850px) calc(100vw - 110px), (max-width: 1069px) calc(100vw - 230px), 840px"
        alt=""
        css={{ width: "100%" }}
      />
    </ShadowPop>
  </section>
)

export default LogoSalad

LogoSalad.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment LogoSaladBlock on SanityLogoSalad {
    title
    image {
      ...ImageWithPreview
    }
  }
`
