import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import theme from "styles/theme"
import IconShadowDefinition from "components/infographics/IconShadowDefinition"
import InvisibleButton from "components/InvisibleButton"
import ShadowPop from "components/ShadowPop"

const TechComparison = ({ entries }) => {
  const [active, setActive] = useState(entries[0]._key)
  const activeEntry = entries.find((e) => e._key === active)

  return (
    <ShadowPop
      css={{
        background: theme.colors.extendedUI.offsetWhite,
        padding: "95px 45px",
        maxWidth: 1228,
        margin: "50px auto",
        [theme.mq.mobile]: {
          maxWidth: 600,
          padding: "35px 25px",
        },
      }}
    >
      <IconShadowDefinition />

      <div
        css={{
          display: "grid",
          gridTemplateColumns: `repeat(${entries.length}, 74px)`,
          gap: 60,
          justifyContent: "center",
          alignItems: "center",
          [theme.mq.mobile]: {
            gridTemplateColumns: `repeat(${entries.length}, 45px)`,
            gap: 30,
          },
        }}
      >
        {entries.map(({ _key, icon, title }) => (
          <InvisibleButton
            key={_key}
            onClick={() => {
              setActive(_key)
            }}
            aria-label={title}
            aria-selected={_key === active}
          >
            <Markdown
              options={{
                overrides: {
                  path: {
                    props: {
                      filter: "url(#icon-shadow)",
                    },
                  },
                },
              }}
              css={{
                transition: "color 500ms",
                color:
                  _key === active
                    ? theme.colors.extended.coral
                    : theme.colors.extendedUI.lightGrey1,
                width: "100%",
              }}
            >
              {icon.iconData}
            </Markdown>
          </InvisibleButton>
        ))}
      </div>

      <div css={{ textAlign: "center" }}>
        <h3
          css={{
            ...theme.h3,
            margin: "50px auto",
            [theme.mq.mobile]: { margin: "30px auto" },
          }}
        >
          {activeEntry.title}
        </h3>
        <ul
          css={{
            maxWidth: 715,
            margin: "0 auto",
            padding: 0,
            listStyle: "none",
            [theme.mq.mobile]: {
              maxWidth: 500,
            },
          }}
        >
          {activeEntry.items.map((entry, index) => (
            <li
              key={index}
              css={{
                margin: "20px auto",
                background: theme.colors.darkBackground.coral,
                padding: "28px 40px",
                borderRadius: 4,
                boxShadow: theme.shadows.shallow,
                [theme.mq.mobile]: {
                  padding: "15px 30px",
                  borderRadius: 2,
                  margin: "10px auto",
                },
              }}
            >
              {entry}
            </li>
          ))}
        </ul>
      </div>
    </ShadowPop>
  )
}

export default TechComparison

TechComparison.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      icon: PropTypes.shape({
        iconData: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export const query = graphql`
  fragment TechComparison on SanityTechComparisonInfographic {
    _key
    _type
    entries {
      _key
      title
      items
      icon {
        iconData
      }
    }
  }
`
