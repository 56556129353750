import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import theme from "styles/theme"
import IconShadowDefinition from "components/infographics/IconShadowDefinition"
import InvisibleButton from "components/InvisibleButton"
import ShadowPop from "components/ShadowPop"
import useTranslate from "hooks/useTranslate"

const SolutionComponents = ({ solutions }) => {
  const [active, setActive] = useState(solutions[0]._key)
  const activeSolution = solutions.find((e) => e._key === active)

  const components = Array.from(
    solutions.reduce((acc, { components }) => {
      components.forEach((comp) => {
        acc.add(comp)
      })
      return acc
    }, new Set())
  )

  return (
    <ShadowPop
      css={{
        background: theme.colors.extendedUI.offsetWhite,
        padding: "85px 45px",
        maxWidth: 1228,
        margin: "50px auto",
        [theme.mq.mobile]: {
          maxWidth: 450,
          margin: "30px auto",
          padding: "35px 25px",
        },
      }}
    >
      <IconShadowDefinition />

      <div
        css={{
          display: "grid",
          gridTemplateColumns: `repeat(${solutions.length}, 74px)`,
          gap: 60,
          justifyContent: "center",
          alignItems: "center",
          [theme.mq.mobile]: {
            gridTemplateColumns: `repeat(${solutions.length}, minmax(auto, 45px))`,
            gap: 25,
          },
        }}
        role="tablist"
        aria-label="Solutions"
      >
        {solutions.map(({ _key, title, icon }) => (
          <InvisibleButton
            key={_key}
            onClick={() => {
              setActive(_key)
            }}
            aria-label={title}
            aria-selected={_key === active}
          >
            <Markdown
              options={{
                overrides: {
                  path: {
                    props: {
                      filter: "url(#icon-shadow)",
                    },
                  },
                },
              }}
              css={{
                transition: "color 500ms",
                color:
                  _key === active
                    ? theme.colors.extended.orange
                    : theme.colors.extendedUI.lightGrey1,
                width: "100%",
              }}
            >
              {icon.iconData}
            </Markdown>
          </InvisibleButton>
        ))}
      </div>

      <div css={{ textAlign: "center" }}>
        <h3
          css={{
            ...theme.h3,
            margin: "50px auto",
            [theme.mq.mobile]: { margin: "30px auto" },
          }}
        >
          {activeSolution.title}
        </h3>
        <ul
          css={{
            maxWidth: 1140,
            margin: "0 auto",
            padding: 0,
            listStyle: "none",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 20,
            [theme.mq.mobile]: {
              gridTemplateColumns: "1fr",
              gap: 10,
            },
          }}
        >
          {components.map((componentName, index) => (
            <Component
              key={index}
              name={componentName}
              on={activeSolution.components.includes(componentName)}
            />
          ))}
        </ul>
      </div>
    </ShadowPop>
  )
}

export default SolutionComponents

SolutionComponents.propTypes = {
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      icon: PropTypes.shape({
        iconData: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

const Component = ({ name, on }) => {
  name = useTranslate(name)
  return (
    <li
      css={{
        background: on
          ? theme.colors.darkBackground.orange
          : theme.colors.extendedUI.lightGrey,
        padding: "28px 40px",
        borderRadius: 4,
        transition: "background 300ms",
        position: "relative",
        ":before, :after": {
          content: "''",
          position: "absolute",
          display: "block",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          transition: "opacity 300ms",
          borderRadius: "inherit",
        },
        ":before": on && {
          opacity: 1,
          boxShadow: theme.shadows.shallow,
        },
        ":after": !on && {
          opacity: 1,
          boxShadow: theme.shadows.inset,
        },
        [theme.mq.mobile]: {
          padding: "15px 30px",
          borderRadius: 2,
        },
      }}
    >
      {name}
    </li>
  )
}

Component.propTypes = {
  name: PropTypes.string.isRequired,
  on: PropTypes.bool.isRequired,
}

export const query = graphql`
  fragment SolutionComponents on SanitySolutionComponentsInfographic {
    _key
    _type
    solutions {
      _key
      title
      components
      icon {
        iconData
      }
    }
  }
`
