import { Appendage } from "components/ArticleTile"
import SimpleHero from "components/blocks/SimpleHero"
import RightCaret from "components/RightCaret"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const ArticleHero = ({ article: { title, slug, category }, image }) => (
  <SimpleHero
    image={image}
    css={{
      padding: "0 25px",
      display: "flex",
      alignItems: "flex-end",
    }}
  >
    <div
      css={{
        width: "100%",
        maxWidth: 1228,
        margin: "0 auto",
        paddingBottom: 35,
        position: "relative",
        zIndex: 2,
      }}
    >
      <Link
        to={slug.current}
        css={{
          display: "block",
          maxWidth: 540,
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <div
          css={theme.expandQueries({
            borderRadius: 4,
            boxShadow: theme.shadows.tight,
            background: theme.colors.floatingContainerBackground,
            padding: [20, 14],
            paddingBottom: 0,
          })}
        >
          <h1
            css={{
              ...theme.h3,
              position: "relative",
              paddingLeft: "0.45em",
              borderLeft: `4px solid ${theme.colors.accentColor}`,
              ":before, :after": {
                content: "''",
                position: "absolute",
                width: 8,
                height: 1,
                left: 0,
                background: theme.colors.accentColor,
              },
              ":before": { top: 0 },
              ":after": { bottom: 0 },
              [theme.mq.mobile]: {
                borderLeftWidth: 2,
                ":before, :after": {
                  width: 4,
                },
              },
            }}
          >
            {title}
          </h1>

          <div
            css={{
              ...theme.p3,
              display: "flex",
              color: theme.colors.secondaryText,
              marginTop: 20,
              borderTop: `solid 1px ${theme.colors.extendedUI.lightGrey}`,
            }}
          >
            <Appendage css={{ flex: "1 1 auto" }}>{category.name}</Appendage>
            <Appendage
              css={{ flex: "0 1 auto", display: "flex", alignItems: "center" }}
            >
              Read More
              <RightCaret width={5} css={{ margin: "0 0.5em" }} />
            </Appendage>
          </div>
        </div>
      </Link>
    </div>
  </SimpleHero>
)

export default ArticleHero

ArticleHero.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    slug: PropTypes.shape({ current: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
}

export const query = graphql`
  fragment ArticleHeroBlock on SanityArticleHero {
    article {
      title
      category {
        name
      }
      slug {
        current
      }
    }
    image {
      ...ImageWithPreview
    }
  }
`
