import Button from "components/Button"
import ContentBlock from "components/ContentBlock"
import InvisibleButton from "components/InvisibleButton"
import ShadowPop from "components/ShadowPop"
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import React, { useState } from "react"
import theme from "styles/theme"

const TabbedTitle = ({
  title,
  description,
  blocks,
  cta,
  poweredByFooter,
  poweredByFooterText,
  poweredByFooterLink,
}) => {
  const [activeIndex, setActiveIndex] = useState(blocks[0]._key)

  return (
    <AnimateSharedLayout>
      <motion.div
        id={title}
        layout
        css={theme.expandFull({
          padding: ["48px 70px 60px", "65px 40px", "50px 25px"],
          // padding: ["48px 25px", "30px 25px"],
          overflow: "hidden",
        })}
      >
        <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
          <h2 css={{ ...theme.h1, textAlign: "center", marginBottom: 24 }}>
            {title}
          </h2>
          {description && <div>{description}</div>}

          {cta && (
            <div
              css={theme.expandQueries({
                textAlign: "center",
                marginTop: [60, 30],
              })}
            >
              <Button href={cta.url}>{cta.title}</Button>
            </div>
          )}
        </div>

        <AnimatePresence initial={false}>
          <ShadowPop
            initial={{ x: "100%", opacity: 0, position: "absolute" }}
            animate={{ x: 0, opacity: 1, position: "static" }}
            exit={{
              x: "-100%",
              opacity: 0,
              position: "absolute",
              width: "100%",
            }}
            transition={{
              stiffness: 300,
              damping: 200,
            }}
            css={theme.expandQueries({
              maxWidth: [1440, 475],
              background: "none",
            })}
          >
            <div
              css={theme.expandQueries({
                display: "flex",
                flexDirection: ["row", "column"],
                gridTemplateColumns: `repeat(${blocks.length}, 1fr)`,
                gap: [0, 0],
                justifyContent: "center",
                margin: "30px auto",
                marginBottom: 0,
                maxWidth: [1440, 475],
                [theme.mq.smallMobile]: {
                  gridTemplateColumns: 250,
                },
              })}
            >
              {blocks.map(({ _key, title }) => (
                <InvisibleButton
                  onClick={() => setActiveIndex(_key)}
                  key={_key}
                  css={{ flex: 1 }}
                >
                  <div
                    css={theme.expandQueries({
                      padding: "0.45em",
                      background: theme.colors.floatingContainerBackground,
                      boxShadow: theme.shadows.tight,
                      borderRadius: "0.2em",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      textAlign: "center",
                      height: "100%",
                      justifyContent: "center",
                    })}
                    style={{
                      borderTop:
                        activeIndex === _key
                          ? "5px solid rgb(171, 117, 250)"
                          : "none",
                    }}
                  >
                    <p
                      css={theme.expandQueries({
                        color: theme.colors.secondaryText,
                        margin: ["20px 0", "10px 0"],
                        fontWeight: 500,
                      })}
                    >
                      {title}
                    </p>
                  </div>
                </InvisibleButton>
              ))}
            </div>
            {blocks.map(({ _key, title, titleImage, cta, content }) => (
              <div
                css={theme.expandQueries({
                  display: ["flex", "block"],
                  background: theme.colors.floatingContainerBackground,
                  maxWidth: [1440, 475],
                  marginTop: [0, 0],
                  // height: 500,
                  maxHeight: 400,
                  boxShadow: theme.shadows.tight,
                  borderRadius: [4, 2],
                  position: "relative",
                  margin: "0 auto",
                  zIndex: 2,
                  [theme.mq.mobile]: {
                    maxHeight: 500,
                  },
                })}
                style={
                  activeIndex === _key
                    ? { visibility: "visible", height: "auto" }
                    : { visibility: "hidden", height: 0 }
                }
                key={_key}
              >
                <div
                  css={theme.expandFull({
                    display: "block",
                    flex: "0 1 50%",
                    alignSelf: "center",
                    padding: [
                      "90px 20px 90px 70px",
                      "60px 20px",
                      "50px 22px 22px",
                    ],
                  })}
                  style={{ flex: titleImage ? "0 1 50%" : "0 1 100%" }}
                >
                  {content && (
                    <ContentBlock
                      content={content}
                      css={{
                        color: theme.colors.secondaryText,
                        fontSize: "0.95rem",
                      }}
                    />
                  )}

                  {cta && (
                    <div css={{ marginTop: 16 }}>
                      <Button href={cta.url}>{cta.title}</Button>
                    </div>
                  )}
                </div>

                {titleImage && (
                  <div
                    css={theme.expandQueries({
                      flex: "0 1 50%",
                      marginLeft: [20, 0],
                      marginTop: [0, 8],
                      position: "relative",
                      overflow: "hidden",
                      ":before": {
                        content: "''",
                        display: "block",
                        paddingBottom: "82.5%",
                        [theme.mq.mobile]: {
                          // paddingBottom: "70.55%",
                          paddingBottom: "45%",
                        },
                      },
                    })}
                  >
                    <SanityImage
                      {...titleImage}
                      // width={1440}
                      width={680}
                      // height={650}
                      height={400}
                      alt=""
                      css={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "block",
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        [theme.mq.mobile]: {
                          width: "50vw",
                          height: "auto",
                          margin: "0 auto",
                        },
                      }}
                      loading="eager"
                      sizes="100vw"
                      sources={{
                        [theme.mq.mobile]: { width: 530, height: 400 },
                        [theme.mq.tablet]: { width: 1100, height: 400 },
                        [theme.mq.desktop]: { width: 1440, height: 650 },
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
            {poweredByFooter && (
              <div
                css={{
                  background: theme.colors.accentBackground,
                  padding: "60px 25px",
                  [theme.mq.mobile]: {
                    padding: "26px 25px",
                  },
                }}
              >
                <div css={{ margin: "0 auto" }}>
                  <h2
                    css={{
                      ...theme.h3,
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    {poweredByFooterText}
                  </h2>
                  {poweredByFooterLink && (
                    <div
                      css={{
                        marginTop: 36,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        [theme.mq.mobile]: {
                          marginTop: 12,
                        },
                      }}
                    >
                      <Button href={poweredByFooterLink.url}>
                        {poweredByFooterLink.title}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </ShadowPop>
        </AnimatePresence>
      </motion.div>
    </AnimateSharedLayout>
  )
}

export default TabbedTitle

export const query = graphql`
  fragment TabbedTitleBlock on SanityTabbedTitle {
    title
    description
    cta {
      ...Link
    }
    blocks {
      _key
      title
      content: _rawContent(resolveReferences: { maxDepth: 6 })
      titleImage {
        ...ImageWithPreview
      }
      cta {
        ...Link
      }
    }
    poweredByFooter
    poweredByFooterText
    poweredByFooterLink {
      ...Link
    }
  }
`
