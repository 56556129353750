import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { AnimatePresence, motion } from "framer-motion"
import theme from "styles/theme"
import ShadowWrapper from "components/ShadowWrapper"
import InvisibleButton from "components/InvisibleButton"
import ContentBlock from "components/ContentBlock"
import RightCaret from "components/RightCaret"

const PeopleGrid = ({ title, people, slug }) => (
  <section
    id={slug}
    css={{
      padding: "80px 25px",
      [theme.mq.mobile]: {
        padding: "30px 25px",
      },
    }}
  >
    <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: 25,
        justifyContent: "center",
        maxWidth: 1228,
        margin: "60px auto 0",
        [theme.mq.tablet]: {
          gridTemplateColumns: "repeat(3, 1fr)",
          marginTop: 30,
        },
        [theme.mq.mobile]: {
          gridTemplateColumns: "repeat(2, 1fr)",
          maxWidth: 670,
        },
        [theme.mq.mobilePortrait]: {
          gridTemplateColumns: "1fr",
          maxWidth: 400,
        },
      }}
    >
      {people.map(({ _key, ...personProps }) => (
        <PersonTile key={_key} {...personProps} />
      ))}
    </div>
  </section>
)

export default PeopleGrid

PeopleGrid.propTypes = {
  title: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      headshot: PropTypes.object.isRequired,
      bio: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

const PersonTile = ({ name, position, headshot, bio }) => {
  const [open, setOpen] = useState(false)

  return (
    <div
      css={{
        padding: "0.45em",
        background: theme.colors.floatingContainerBackground,
        boxShadow: theme.shadows.tight,
        borderRadius: "0.2em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <div
        css={{
          ...theme.h4,
          padding: "0.375em",
          marginBottom: "0.625em",
          minHeight: "3em",
        }}
      >
        <h3 css={{ fontSize: "inherit", fontWeight: 500 }}>{name}</h3>
        <div css={theme.h4}>{position}</div>
      </div>
      <div>
        <InvisibleButton
          onClick={() => {
            setOpen((curr) => !curr)
          }}
          css={{
            ...theme.p3,
            display: "inline-flex",
            alignItems: "center",
            color: theme.colors.softText,
            margin: "0 0 10px 10px",
          }}
        >
          {open ? (
            <>
              Close Bio
              <CloseIcon width={8} css={{ marginLeft: 10 }} />
            </>
          ) : (
            <>
              Open Bio
              <RightCaret width={5} css={{ marginLeft: 10 }} />
            </>
          )}
        </InvisibleButton>

        <ShadowWrapper css={{ overflow: "hidden" }}>
          <SanityImage
            {...headshot}
            width={300}
            height={246}
            sizes="(max-width: 449px) calc(100vw - 62px), (max-width: 530px) 387px, (max-width: 850px) calc((min(720px, 100vw) - 75px - (6.3px * 4)) / 2), (max-width: 1100px) calc((100vw - 100px - (8.1px * 6)) / 3), calc((min(1228px, 100vw - 50px) - 170px) / 4)"
            alt=""
            css={{
              objectFit: "cover",
              width: "100%",
              "&[data-lqip]": { height: 246 },
              "&[data-loading]": {
                marginTop: -10, // avoid spilling out of overflow hidden parent
              },
            }}
          />

          <AnimatePresence>
            {open && (
              <motion.div
                key="bio"
                initial={{ y: "100%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: "100%", opacity: 0 }}
                transition={{
                  type: "spring",
                  damping: 30,
                  stiffness: 300,
                }}
                css={{
                  position: "absolute",
                  left: -2,
                  bottom: -2,
                  right: -2,
                  height: "calc(100% + 4px)",
                  overflowY: "auto",
                  WebkitOverflowScrolling: "touch",
                  padding: "0 12px",
                  background: theme.colors.floatingContainerBackground,
                  zIndex: 3,
                  ":after": {
                    content: "''",
                    position: "sticky",
                    display: "block",
                    bottom: -2,
                    left: 0,
                    right: 0,
                    height: 50,
                    background: `linear-gradient(180deg, transparent 0%, ${theme.colors.floatingContainerBackground} 90%)`,
                    pointerEvents: "none",
                  },
                }}
              >
                <ContentBlock
                  content={bio}
                  css={{
                    ...theme.p3,
                    "p:first-of-type": {
                      marginTop: 0,
                    },
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </ShadowWrapper>
      </div>
    </div>
  )
}

PersonTile.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  headshot: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  bio: PropTypes.array.isRequired,
}

const CloseIcon = (props) => (
  <svg viewBox="0 0 10 10" {...props}>
    <path
      stroke={theme.colors.accentColor}
      strokeWidth="2"
      d="M9 1L1 9M1 1l8 8"
    />
  </svg>
)

export const query = graphql`
  fragment PeopleGridBlock on SanityPeopleGrid {
    title
    people {
      _key
      name
      position
      headshot {
        ...ImageWithPreview
      }
      bio: _rawBio
    }
  }
`
