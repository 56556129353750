import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion"
import ShadowPop from "components/ShadowPop"
import ShadowWrapper from "components/ShadowWrapper"
import Button from "components/Button"
import RightCaret from "components/RightCaret"
import theme from "styles/theme"
import ContentBlock from "components/ContentBlock"
import InvisibleButton from "components/InvisibleButton"

const HighlightCarousel = ({
  title,
  richCopy,
  highlights,
  cta,
  slug,
  accented,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const activeHighlight = highlights[activeIndex]

  return (
    <AnimateSharedLayout>
      <motion.div
        id={slug}
        layout
        css={theme.expandFull({
          background: accented && theme.colors.accentBackground,
          padding: ["85px 70px", "85px 40px", "50px 25px"],
          overflow: "hidden",
        })}
      >
        <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
          <h2 css={{ ...theme.h1, textAlign: "center", marginBottom: 24 }}>
            {title}
          </h2>

          {richCopy && <ContentBlock content={richCopy} />}
        </div>

        <AnimatePresence initial={false}>
          <ShadowPop
            key={activeIndex}
            initial={{ x: "100%", opacity: 0, position: "absolute" }}
            animate={{ x: 0, opacity: 1, position: "static" }}
            exit={{
              x: "-100%",
              opacity: 0,
              position: "absolute",
              width: "100%",
            }}
            transition={{
              stiffness: 300,
              damping: 200,
            }}
            css={theme.expandQueries({
              display: ["flex", "block"],
              padding: [10, 8],
              background: theme.colors.floatingContainerBackground,
              maxWidth: [1440, 475],
              marginTop: [48, 24],
            })}
          >
            <div
              css={theme.expandFull({
                display: "block",
                flex: "0 1 50%",
                alignSelf: "center",
                padding: ["90px 20px 90px 70px", "60px 20px", "50px 22px 22px"],
              })}
            >
              <CarouselControls
                currentItem={activeIndex + 1}
                itemCount={highlights.length}
                setActive={(fn) => setActiveIndex((prev) => fn(prev + 1) - 1)}
                css={{ position: "absolute", top: 25, display: "flex" }}
              />

              <h3
                css={theme.expandQueries({
                  ...theme.h2,
                  maxWidth: ["none", 335],
                })}
              >
                {activeHighlight.title}
              </h3>

              {activeHighlight.copy && (
                <p
                  css={theme.expandQueries({
                    color: theme.colors.secondaryText,
                    maxWidth: [440, 360],
                    margin: ["20px 0", "10px 0"],
                  })}
                >
                  {activeHighlight.copy}
                </p>
              )}

              {activeHighlight.cta && (
                <div
                  css={theme.expandQueries({
                    margin: ["20px 0 0", "10px 0 0"],
                  })}
                >
                  <Button href={activeHighlight.cta.url}>
                    {activeHighlight.cta.title}
                  </Button>
                </div>
              )}
            </div>

            <ShadowWrapper
              css={theme.expandQueries({
                flex: "0 1 50%",
                marginLeft: [20, 0],
                marginTop: [0, 8],
                position: "relative",
                overflow: "hidden",
                ":before": {
                  content: "''",
                  display: "block",
                  paddingBottom: "82.5%",
                  [theme.mq.mobile]: {
                    paddingBottom: "70.55%",
                  },
                },
              })}
            >
              <SanityImage
                {...activeHighlight.image}
                alt=""
                width={630}
                sizes="(max-width: 525px) calc(100vw - 66px), (max-width: 850px) 459px, (max-width: 1580px) calc((100vw - 180px) / 2), 700px"
                css={theme.expandQueries({
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                })}
              />
            </ShadowWrapper>
          </ShadowPop>
        </AnimatePresence>

        {cta && (
          <div
            css={theme.expandQueries({
              textAlign: "center",
              marginTop: [60, 30],
            })}
          >
            <Button href={cta.url}>{cta.title}</Button>
          </div>
        )}
      </motion.div>
    </AnimateSharedLayout>
  )
}

export default HighlightCarousel

HighlightCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  richCopy: PropTypes.array,
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string,
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      image: PropTypes.shape({
        asset: PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string,
  accented: PropTypes.bool,
}

const CarouselControls = ({ currentItem, itemCount, setActive, className }) => {
  const atStart = currentItem === 1
  const atEnd = currentItem === itemCount

  return (
    <div className={className}>
      <InvisibleButton
        onClick={() => {
          setActive((prev) => Math.max(1, prev - 1))
        }}
        aria-label="Previous Slide"
        css={{
          transform: "rotateY(-180deg)",
          opacity: atStart ? 0.5 : 1,
          pointerEvents: atStart && "none",
        }}
      >
        <RightCaret width={10} />
      </InvisibleButton>

      <div
        css={{
          padding: "0 0.5em",
          color: theme.colors.accentColor,
          minWidth: "3em",
          textAlign: "center",
        }}
      >
        {currentItem}/{itemCount}
      </div>

      <InvisibleButton
        css={{
          cursor: "pointer",
          opacity: atEnd ? 0.5 : 1,
          pointerEvents: atEnd && "none",
        }}
        onClick={() => {
          setActive((prev) => Math.min(itemCount, prev + 1))
        }}
        aria-label="Next Slide"
      >
        <RightCaret width={10} />
      </InvisibleButton>
    </div>
  )
}

CarouselControls.propTypes = {
  currentItem: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export const query = graphql`
  fragment HighlightCarouselBlock on SanityHighlightCarousel {
    title
    richCopy: _rawCopy
    highlights {
      title
      copy
      image {
        ...ImageWithPreview
      }
      cta {
        ...Link
      }
    }
    cta {
      ...Link
    }
    accented
  }
`
