import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import Button from "components/Button"
import ContentBlock from "components/ContentBlock"

const SimpleText = ({ title, richCopy, cta, slug }) => (
  <div
    id={slug}
    css={theme.expandQueries({ padding: ["48px 25px", "30px 25px"] })}
  >
    <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
      <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

      <ContentBlock
        content={richCopy}
        css={theme.expandQueries({
          marginTop: [30, 20],
        })}
      />

      {cta && (
        <div
          css={theme.expandQueries({
            textAlign: "center",
            marginTop: [30, 20],
          })}
        >
          <Button href={cta.url}>{cta.title}</Button>
        </div>
      )}
    </div>
  </div>
)

export default SimpleText

SimpleText.propTypes = {
  title: PropTypes.string.isRequired,
  richCopy: PropTypes.array.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string,
}

export const query = graphql`
  fragment SimpleTextBlock on SanitySimpleText {
    title
    richCopy: _rawCopy
    cta {
      ...Link
    }
  }
`
