import React from "react"
import theme from "styles/theme"

const RightCaret = (props) => (
  <svg viewBox="0 0 5 10" {...props}>
    <path fill={theme.colors.accentColor} d="M0 0v10l5-5-5-5z" />
  </svg>
)

export default RightCaret
