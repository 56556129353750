import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import ContentBlock from "components/ContentBlock"
import Button from "components/Button"
import ComparisonList from "components/infographics/ComparisonList"
import TechComparison from "components/infographics/TechComparison"
import SolutionComponents from "components/infographics/SolutionComponents"
import Flowchart from "components/infographics/Flowchart"

const CONTENT_TYPE_MAP = {
  comparisonListInfographic: ComparisonList,
  techComparisonInfographic: TechComparison,
  solutionComponentsInfographic: SolutionComponents,
  flowchartInfographic: Flowchart,
}

const Infographic = ({ title, richCopy, content: [content], cta, slug }) => (
  <div
    id={slug}
    css={theme.expandQueries({
      padding: ["48px 25px", "30px 25px"],
      background: theme.colors.core.lightGrey,
      color: theme.colors.core.darkGrey,
    })}
  >
    <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
      <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

      {richCopy && (
        <ContentBlock
          content={richCopy}
          css={theme.expandQueries({
            marginTop: [30, 20],
            color: theme.colors.extendedUI.grey3,
          })}
        />
      )}
    </div>

    {React.createElement(CONTENT_TYPE_MAP[content._type], content)}

    {cta && (
      <div
        css={theme.expandQueries({
          textAlign: "center",
          marginTop: [60, 30],
        })}
      >
        <Button href={cta.url}>{cta.title}</Button>
      </div>
    )}
  </div>
)

export default Infographic

Infographic.propTypes = {
  title: PropTypes.string.isRequired,
  richCopy: PropTypes.array,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      _type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string,
}

export const query = graphql`
  fragment InfographicBlock on SanityInfographic {
    title
    richCopy: _rawCopy
    content {
      ...ComparisonList
      ...TechComparison
      ...SolutionComponents
      ...Flowchart
    }
    cta {
      ...Link
    }
  }
`
