import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import theme from "styles/theme"
import ReactPlayer from "react-player"
import Button from "components/Button"
import ShadowWrapper from "components/ShadowWrapper"
import InvisibleButton from "components/InvisibleButton"

const breakpoint = theme.mq.max(760)

const CaseStudies = ({ title, caseStudies, slug }) => (
  <section
    id={slug}
    css={{
      padding: "100px 0 20px",
      [breakpoint]: {
        padding: "50px 0 10px",
      },
    }}
  >
    <h2
      css={{
        ...theme.h1,
        textAlign: "center",
        padding: "0 25px",
        margin: "0 auto",
        maxWidth: 1228,
      }}
    >
      {title}
    </h2>

    {caseStudies.map(({ _key, ...caseStudyProps }) => (
      <CaseStudy key={_key} {...caseStudyProps} />
    ))}
  </section>
)

export default CaseStudies

CaseStudies.propTypes = {
  title: PropTypes.string.isRequired,
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
      video: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
      image: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }),
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

const CaseStudy = ({ title, intro, cta, video, image }) => {
  const [playing, setPlaying] = useState(false)

  return (
    <div
      css={{
        padding: "0 25px",
        margin: "60px 0",
        "& + &": {
          marginTop: 100,
          paddingTop: 100,
          borderTop: `1px solid ${theme.colors.core.lightPurple}`,
        },
        [breakpoint]: {
          margin: "30px 0",
          "& + &": {
            marginTop: 30,
            paddingTop: 30,
          },
        },
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: 1228,
          margin: "0 auto",
          [breakpoint]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        }}
      >
        <div
          css={{
            flex: "1 1 400px",
            maxWidth: 460,
            marginRight: 60,
            [breakpoint]: {
              flex: "1 1 auto",
              margin: "20px 0 0",
              order: 2,
            },
          }}
        >
          <h3
            css={{
              ...theme.h2,
              marginBottom: 20,
              [breakpoint]: {
                marginBottom: 10,
              },
            }}
          >
            {title}
          </h3>
          <p css={{ ...theme.p2, color: theme.colors.secondaryText }}>
            {intro}
          </p>

          {cta && <Button href={cta.url}>{cta.title}</Button>}
        </div>

        <ShadowWrapper
          shadow={theme.shadows.image}
          css={{
            flex: "0 1 708px",
            [breakpoint]: {
              flex: "1 1 auto",
            },
          }}
        >
          {image ? (
            <SanityImage
              {...image}
              width={708}
              height={440}
              sizes="(max-width: 760px) calc(100vw - 50px), (max-width: 1218px) calc((100vw - 110px) * 0.63898917), 708px"
              alt=""
              css={{ width: "100%" }}
            />
          ) : (
            <>
              <ReactPlayer
                url={video.asset.url}
                playing={playing}
                controls={playing}
                width="100%"
                height="100%"
                onEnded={() => {
                  setPlaying(false)
                }}
                css={{ video: { borderRadius: 4 } }}
              />
              <InvisibleButton
                aria-label="Play"
                css={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pointerEvents: playing ? "none" : "auto",
                  opacity: playing ? 0 : 1,
                  transition: "opacity 300ms",
                }}
                onClick={() => {
                  setPlaying(true)
                }}
              >
                <PlayButtonIcon
                  css={{ width: 80, [breakpoint]: { width: 66 } }}
                />
              </InvisibleButton>
            </>
          )}
        </ShadowWrapper>
      </div>
    </div>
  )
}

CaseStudy.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  image: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }),
}

const PlayButtonIcon = (props) => (
  <svg fill="none" viewBox="0 0 80 80" {...props}>
    <circle cx="40" cy="40" r="38" stroke="#fff" strokeWidth="4" />
    <path
      fill="#fff"
      d="M52.2 36.8a4 4 0 010 7.2l-15.4 7.8a4 4 0 01-5.8-3.6V32.6c0-3 3.1-5 5.8-3.6l15.4 7.8z"
    />
  </svg>
)

export const query = graphql`
  fragment CaseStudiesBlock on SanityCaseStudies {
    title
    caseStudies {
      _key
      title
      intro
      cta {
        ...Link
      }
      video {
        asset {
          url
        }
      }
      image {
        ...ImageWithPreview
      }
    }
  }
`
