import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import ShadowPop from "components/ShadowPop"
import Button from "components/Button"

const Quote = ({ text, author, author2, slug, cta }) => (
  <div
    id={slug}
    css={{
      padding: "50px 35px",
      [theme.mq.mobile]: { padding: "30px 20px" },
    }}
  >
    <ShadowPop
      css={theme.expandFull({
        maxWidth: 1020,
        padding: ["70px 100px", "40px 60px", "30px 20px"],
      })}
    >
      <h2
        css={{
          ...theme.h3,
          position: "relative",
          borderLeft: `4px solid ${theme.colors.accentColor}`,
          paddingLeft: 30,
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: 12,
            height: 2,
            left: 0,
            background: theme.colors.accentColor,
          },
          ":before": { top: 0 },
          ":after": { bottom: 0 },
          [theme.mq.mobile]: {
            borderLeftWidth: 2,
            paddingLeft: 12,
            ":before, :after": {
              width: 8,
              height: 1,
            },
          },
        }}
      >
        “{text}”
      </h2>

      <div
        css={{
          marginTop: 24,
          color: theme.colors.accentColor,
        }}
      >
        {author}
      </div>
      {author && (
        <div
          css={{
            ...theme.p3,
            maxWidth: 500,
          }}
        >
          {author2}
        </div>
      )}
      {cta && (
        <div
          css={theme.expandQueries({
            margin: ["20px 0 0", "10px 0 0"],
          })}
        >
          <Button href={cta.url}>{cta.title}</Button>
        </div>
      )}
    </ShadowPop>
  </div>
)

export default Quote

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment QuoteBlock on SanityQuote {
    text
    author
    author2
    cta {
      ...Link
    }
  }
`
