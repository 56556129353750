import Button from "components/Button"
import GifVideo from "components/GifVideo"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const TextHero = ({ title, cta, image, video, alignment = "center" }) => (
  <div
    css={{
      position: "relative",
      padding: alignment === "center" ? "110px 0 0" : "110px 100px 0", // offset header
      [theme.mq.mobile]: {
        padding: alignment === "center" ? "65px 0 0" : "65px 50px 0",
      },
      [theme.mq.smallMobile]: {
        padding: "65px 0 0",
      },
      ":after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#2A282D",
        opacity: 0.5,
        zIndex: 4,
      },
    }}
  >
    <div
      css={theme.expandQueries({
        display: "flex",
        flexDirection: "column",
        alignItems:
          alignment === "left"
            ? "flex-start"
            : alignment === "right"
            ? "flex-end"
            : "center",
        justifyContent: "center",
        position: "relative",
        zIndex: 5,
        maxWidth: "32em",
        margin:
          alignment === "left"
            ? "0 auto 0 0"
            : alignment === "right"
            ? "0 0 0 auto"
            : "0 auto",
        padding: ["100px 25px", "50px 25px"],
        textAlign:
          alignment === "left"
            ? "left"
            : alignment === "right"
            ? "right"
            : "center",
        height: 650,
        maxHeight: "min(70vh, 650px)",
        minHeight: 300,
        [theme.mq.tablet]: {
          height: 400,
          minHeight: 200,
          maxHeight: "min(50vh, 500px)",
        },
        [theme.mq.smallMobile]: {
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        },
      })}
    >
      <h1
        css={{
          ...theme.h1,
          color: theme.colors.extendedUI.offsetWhite,
          textShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.15), 0px 0px 40px rgba(0, 0, 0, 0.2)",
        }}
      >
        {title}
      </h1>

      {cta && (
        <Button
          href={cta.url}
          css={{ marginTop: 30, [theme.mq.mobile]: { marginTop: 20 } }}
        >
          {cta.title}
        </Button>
      )}
    </div>

    {video ? (
      <GifVideo
        src={video.asset.url}
        width="100%"
        height="100%"
        css={{
          display: "block",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />
    ) : (
      <SanityImage
        {...image}
        width={1440}
        height={650}
        alt=""
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "block",
          objectFit: "cover",
          width: "100%",
          height: "100%",
          // zIndex: 3,
        }}
        loading="eager"
        sizes="100vw"
        sources={{
          [theme.mq.mobile]: { width: 530, height: 400 },
          [theme.mq.tablet]: { width: 1100, height: 400 },
          [theme.mq.desktop]: { width: 1440, height: 650 },
        }}
      />
    )}
  </div>
)

export default TextHero

TextHero.propTypes = {
  title: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }),
  alignment: PropTypes.oneOf(["left", "center", "right"]),
}

export const query = graphql`
  fragment TextHeroBlock on SanityTextHero {
    title
    cta {
      ...Link
    }
    video {
      asset {
        url
      }
    }
    image {
      ...ImageWithPreview
    }
    alignment
  }
`
