import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ValidatedForm, {
  useFormField,
  validateEmail,
} from "@coreyward/validated-form"
import theme from "styles/theme"
import ShadowPop from "components/ShadowPop"
import ArrowButton from "components/ArrowButton"
import submitToNetlify from "lib/submitToNetlify"
import useTranslate from "hooks/useTranslate"

const NewsletterSignup = ({ title, successMessage, slug }) => (
  <div
    id={slug}
    css={theme.expandFull({
      padding: ["100px 50px", "60px 40px", "50px 25px"],
    })}
  >
    <ShadowPop
      css={{
        maxWidth: 1300,
        padding: 34,
        background: theme.colors.floatingContainerBackground,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.mq.smallMobile]: {
          display: "block",
        },
      }}
    >
      <h2
        css={{
          ...theme.p1,
          fontWeight: 500,
          marginRight: "2em",
          [theme.mq.smallMobile]: {
            marginRight: 0,
            marginBottom: 30,
          },
        }}
      >
        {title}
      </h2>

      <ValidatedForm
        onSubmit={({ fields }) =>
          submitToNetlify("newsletter-signup", {
            email: fields.email.value,
          }).then((response) => response.ok)
        }
        renderSuccess={
          <div css={{ flex: "1 1 auto", color: theme.colors.extended.mint }}>
            {successMessage}
          </div>
        }
        css={{
          flex: "1 1 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <EmailField />

        <ArrowButton css={{ marginLeft: "2em" }} />
      </ValidatedForm>
    </ShadowPop>
  </div>
)

export default NewsletterSignup

NewsletterSignup.propTypes = {
  title: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  slug: PropTypes.string,
}

const EmailField = () => {
  const { errorMessage, value = "", ...eventListeners } = useFormField({
    name: "email",
    validator: validateEmail,
    required: true,
  })
  const placeholder = useTranslate("Email address")
  const localizedErrorMessage = useTranslate(errorMessage)

  const color = errorMessage
    ? theme.colors.extended.coral
    : theme.colors.extended.mint

  return (
    <>
      <div
        css={{
          position: "relative",
          flex: "1 1 600px",
          maxWidth: 600,
          borderBottom: `2px solid ${color}`,
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: 1,
            height: 3,
            bottom: 0,
            background: color,
          },
          ":before": { left: 0 },
          ":after": { right: 0 },
        }}
      >
        <input
          name="email"
          type="email"
          placeholder={placeholder}
          required={true}
          value={value}
          css={{
            ...theme.p3,
            display: "block",
            appearance: "none",
            fontFamily: "inherit",
            border: 0,
            borderRadius: 0,
            color: "inherit",
            background: "inherit",
            width: "100%",
            padding: "3px 10px",
          }}
          {...eventListeners}
        />
        {errorMessage && (
          <div
            css={{
              ...theme.p3,
              fontSize: 14,
              color,
              position: "absolute",
              bottom: "-1.75em",
              [theme.mq.max(400)]: {
                display: "none",
              },
            }}
          >
            {localizedErrorMessage}
          </div>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  fragment NewsletterSignupBlock on SanityNewsletterSignup {
    title
    successMessage
  }
`
