import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Player from "react-player"
import { ReactComponent as PlayButton } from "assets/PlayButton.svg"
import theme from "styles/theme"

const InlineVideo = ({ title, subtext, video, autoplay, slug }) => {
  const [playing, setPlaying] = useState(autoplay)

  const stopPlaying = () => {
    setPlaying(false)
  }

  return (
    <div
      id={slug}
      css={{
        // Always use dark theme
        color: theme.colors.extendedUI.offsetWhite,
        background: theme.colors.core.darkGrey,
        position: "relative",
      }}
    >
      <Player
        url={video.asset.url}
        playing={playing}
        controls={playing && !autoplay}
        onPause={stopPlaying}
        onEnded={autoplay ? null : stopPlaying}
        muted={autoplay}
        loop={autoplay}
        playsinline
        width="100%"
        height="auto"
        css={{
          position: "relative",
          ":before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(15, 15, 15, 0.4)",
            pointerEvents: "none",
          },
          video: {
            display: "block",
          },
        }}
      />

      {!autoplay && (
        <PlayButton
          width={100}
          height={100}
          onClick={() => {
            setPlaying((prev) => !prev)
          }}
          css={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -50,
            marginLeft: -50,
            cursor: "pointer",
            opacity: playing ? 0 : 1,
            pointerEvents: playing ? "none" : "initial",
            transition: "opacity 100ms ease",
            [theme.mq.mobile]: {
              transform: "scale(0.66)",
            },
          }}
        />
      )}

      <div
        css={{
          position: "absolute",
          left: 100,
          bottom: 50,
          opacity: !autoplay && playing ? 0 : 1,
          pointerEvents: !autoplay && playing ? "none" : "initial",
          transition: "opacity 500ms ease-in",
          [theme.mq.mobile]: {
            display: "none",
          },
        }}
      >
        <h3 css={{ ...theme.h3 }}>{title}</h3>
        {subtext && <div css={{ ...theme.p3, marginTop: 10 }}>{subtext}</div>}
      </div>
    </div>
  )
}

export default InlineVideo

InlineVideo.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  autoplay: PropTypes.bool.isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment InlineVideoBlock on SanityInlineVideo {
    title
    subtext
    video {
      asset {
        url
      }
    }
    autoplay
  }
`
