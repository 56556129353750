import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const QuickInfo = ({ title, items, slug }) => (
  <section
    id={slug}
    css={{
      background: theme.colors.highlightBackground,
      padding: "80px 25px",
      [theme.mq.mobile]: {
        padding: "30px 25px",
      },
    }}
  >
    <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>
    <div
      css={{
        display: "grid",
        // gridTemplateColumns: `repeat(${items.length === 4 ? 2 : 3}, 1fr)`,
        gridTemplateColumns: `repeat(${items.length % 2 === 0 ? 2 : 3}, 1fr)`,
        gap: "60px 56px",
        justifyContent: "center",
        maxWidth: 1192,
        margin: "60px auto 0",
        [theme.mq.mobile]: {
          gridTemplateColumns: "repeat(2, minmax(auto, 300px))",
          gap: "20px 24px",
          marginTop: 30,
        },
        [theme.mq.mobilePortrait]: {
          gridTemplateColumns: "minmax(auto, 300px)",
        },
      }}
    >
      {items.map(({ _key, title, copy }) => (
        <div key={_key}>
          <h3 css={{ ...theme.p1, fontWeight: 400, marginBottom: 10 }}>
            {title}
          </h3>
          <div
            css={{ ...theme.p2, color: theme.colors.softText, opacity: 0.8 }}
          >
            {copy}
          </div>
        </div>
      ))}
    </div>
  </section>
)

export default QuickInfo

QuickInfo.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment QuickInfoBlock on SanityQuickInfo {
    title
    items {
      _key
      title
      copy
    }
  }
`
