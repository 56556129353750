import AutoLink from "components/AutoLink"
import Button from "components/Button"
import ShadowWrapper from "components/ShadowWrapper"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const ReadMore = ({ title, tiles, slug }) => (
  <section
    id={slug}
    css={{
      background: theme.colors.accentBackground,
      // padding: "80px 25px",
      padding: "48px 25px",
      [theme.mq.mobile]: {
        padding: "30px 25px",
      },
    }}
  >
    <div css={{ maxWidth: 1228, margin: "0 auto" }}>
      <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

      <div
        css={{
          display: "grid",
          // gridTemplateColumns: `repeat(2, 1fr)`,
          gridTemplateColumns: `repeat(4, 1fr)`,
          gap: 20,
          justifyContent: "center",
          alignItems: "stretch",
          marginTop: 50,
          [theme.mq.max(700)]: {
            gridTemplateColumns: "1fr",
            maxWidth: 300,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        {tiles.map(({ _key, link, coverImage, title, description }) => (
          <AutoLink
            key={_key}
            to={link.url}
            css={{
              gridColumnStart: tiles.length === 1 ? 2 : "auto",
              gridColumnEnd: tiles.length === 1 ? 4 : "auto",
              gridColumn: tiles.length > 1 ? "span 2" : null,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textDecoration: "none",
              color: theme.colors.primaryText,
              background: theme.colors.floatingContainerBackground,
              boxShadow: theme.shadows.tight,
              borderRadius: 4,
              padding: 10,
            }}
          >
            <h3
              css={{
                ...theme.h3,
                padding: 10,
                maxWidth: 450,
              }}
            >
              {title ?? link.title}
            </h3>

            <div
              css={{
                padding: 10,
                maxWidth: 450,
                marginBottom: 10,
              }}
            >
              {description}
            </div>

            {title && (
              <div
                css={theme.expandQueries({
                  margin: ["20px 0 0", "10px 0 0"],
                  // textAlign: ["center", "left"],
                  textAlign: "left",
                  marginBottom: 24,
                })}
              >
                <Button
                  href={link.url}
                  css={theme.expandQueries({
                    marginBottom: [12, null, 0],
                  })}
                >
                  {link.title}
                </Button>
              </div>
            )}

            {coverImage && (
              <ShadowWrapper>
                <SanityImage
                  {...coverImage}
                  width={586}
                  // height={430}
                  height={280}
                  alt=""
                  css={{
                    width: "100%",
                    "&[data-lqip]": {
                      // height: 430
                      height: 280,
                    },
                  }}
                />
              </ShadowWrapper>
            )}
          </AutoLink>
        ))}
      </div>
    </div>
  </section>
)

export default ReadMore

ReadMore.propTypes = {
  title: PropTypes.string,
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      coverImage: PropTypes.shape({
        asset: PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment ReadMoreBlock on SanityReadMore {
    title
    tiles {
      _key
      title
      description
      link {
        title
        url
      }
      coverImage {
        ...ImageWithPreview
      }
    }
  }
`
