import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import theme from "styles/theme"

const OpenIndicator = ({ open, ...props }) => (
  <motion.div
    css={theme.expandFull({
      position: "relative",
      color: theme.colors.primaryBackground,
      width: [52, 40, 28],
      height: [52, 40, 28],
      marginLeft: "2em",
      flex: "0 0 auto",
      fill: theme.colors.darkBackground.orange,
      ":after": {
        boxShadow: open ? theme.shadows.button : theme.shadows.buttonTight,
        borderRadius: "100%",
        content: "''",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 3,
      },
    })}
    animate={{
      fill: open
        ? theme.colors.extendedUI.orangeInset
        : theme.colors.darkBackground.orange,
    }}
    {...props}
  >
    <svg viewBox="0 0 52 52">
      <circle cx="26" cy="26" r="26" />
      <rect
        width="3"
        height="22"
        x="24.5"
        y="15"
        fill="currentColor"
        rx=".5"
        css={{ opacity: open ? 0 : 1 }}
      />
      <rect
        width="3"
        height="22"
        x="37"
        y="24.5"
        fill="currentColor"
        rx=".5"
        transform="rotate(90 37 24.5)"
      />
    </svg>
  </motion.div>
)

export default OpenIndicator

OpenIndicator.propTypes = {
  open: PropTypes.bool,
}
