import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import ShadowWrapper from "components/ShadowWrapper"
import Button from "components/Button"
import ContentBlock from "components/ContentBlock"
import theme from "styles/theme"

const CardList = ({ title, richCopy, slug, cards }) => (
  <div
    id={slug}
    css={{
      padding: "100px 24px",
    }}
  >
    <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
      {title && <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>}

      {richCopy && (
        <ContentBlock
          content={richCopy}
          css={theme.expandQueries({
            marginTop: [30, 20],
          })}
        />
      )}
    </div>

    <div
      css={{
        margin: "50px auto 0",
        maxWidth: 1020,
        [theme.mq.mobile]: { marginTop: 30 },
      }}
    >
      {cards.map(({ _key, ...rest }) => (
        <Card key={_key} {...rest} />
      ))}
    </div>
  </div>
)

export default CardList

const Card = ({ title, copy, cta, image }) => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: image ? "1fr 300px" : "1fr",
      gap: 12,
      background: theme.colors.floatingContainerBackground,
      boxShadow: theme.shadows.tight,
      padding: 12,
      marginBottom: 16,
      borderRadius: 4,
      [theme.mq.smallMobile]: {
        gridTemplateColumns: "1fr",
        maxWidth: 375,
        marginLeft: "auto",
        marginRight: "auto",
      },
    }}
  >
    <div
      css={{
        padding: 12,
        [theme.mq.smallMobile]: { order: image ? 2 : 1, padding: 0 },
      }}
    >
      <h3 css={{ ...theme.h3 }}>{title}</h3>
      {copy && (
        <div css={{ color: theme.colors.softText, marginTop: 8 }}>{copy}</div>
      )}

      {cta && (
        <div css={{ marginTop: 16 }}>
          <Button href={cta.url}>{cta.title}</Button>
        </div>
      )}
    </div>
    {image && (
      <ShadowWrapper
        css={{ alignSelf: "start", [theme.mq.smallMobile]: { order: 1 } }}
      >
        <SanityImage
          {...image}
          width={300}
          alt=""
          css={{
            [theme.mq.smallMobile]: {
              width: "100%",
              maxHeight: "30vh",
              objectFit: "cover",
            },
          }}
        />
      </ShadowWrapper>
    )}
  </div>
)

Card.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }),
}

CardList.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  richCopy: PropTypes.arrayOf(PropTypes.object.isRequired),
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes).isRequired)
    .isRequired,
}

export const query = graphql`
  fragment CardListBlock on SanityCardList {
    title
    richCopy: _rawCopy
    cards {
      _key
      title
      copy
      cta {
        ...Link
      }
      image {
        ...ImageWithPreview
      }
    }
  }
`
