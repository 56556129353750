import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import theme from "styles/theme"
import ContentBlock from "components/ContentBlock"
import ShadowWrapper from "components/ShadowWrapper"
import ShadowPop from "components/ShadowPop"
import GifVideo from "components/GifVideo"

const Features = ({ title, description, features, slug }) => (
  <section
    id={slug}
    css={{
      maxWidth: 1228 + 50,
      margin: "50px auto",
      padding: "0 25px",
      "& + &": {
        marginTop: 100,
        [theme.mq.mobile]: {
          marginTop: 50,
        },
      },
    }}
  >
    {title && (
      <h2
        css={{
          ...theme.h2,
          paddingLeft: "0.45em",
          borderLeft: `4px solid ${theme.colors.accentColor}`,
          position: "relative",
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: 8,
            height: 1,
            left: 0,
            background: theme.colors.accentColor,
          },
          ":before": { top: 0 },
          ":after": { bottom: 0 },
          [theme.mq.mobile]: {
            borderLeftWidth: 2,
            ":before, :after": {
              width: 4,
            },
          },
        }}
      >
        {title}
      </h2>
    )}

    {description && (
      <p
        css={{
          ...theme.p2,
          maxWidth: title ? "36em" : "35em",
          paddingLeft: title ? "1.09em" : 0,
          color: theme.colors.secondaryText,
          margin: "0.675em 0 0 0",
          [theme.mq.mobile]: {
            paddingLeft: 0,
          },
        }}
      >
        {description}
      </p>
    )}

    <div css={{ marginTop: "1.82em" }}>
      {features.length === 1 ? (
        <MediaFeature {...features[0]} />
      ) : (
        features.map(({ _key, ...props }, index) => (
          <FeatureBlock key={_key} {...props} flipped={index % 2 !== 0} />
        ))
      )}
    </div>
  </section>
)

export default Features

Features.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.array.isRequired,
      image: PropTypes.shape({
        asset: PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      }),
      video: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

const FeatureBlock = ({ copy, image, video, flipped = false }) => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: flipped ? "42.35% 1fr" : "57.65% 1fr",
      gap: 20,
      alignItems: "start",
      marginBottom: "2.27em",
      [theme.mq.smallMobile]: {
        gridTemplateColumns: "1fr",
      },
    }}
  >
    <ShadowWrapper
      shadow={theme.shadows.image}
      css={{ order: flipped ? 2 : 1, [theme.mq.smallMobile]: { order: 1 } }}
    >
      {video ? (
        <GifVideo src={video.asset.url} css={{ width: "100%" }} />
      ) : (
        <SanityImage
          {...image}
          width={708}
          alt=""
          css={{ width: "100%" }}
          sizes="(max-width: 648px) calc(100vw - 50px), (max-width: 1278px) calc((100vw - 50px) / 1.735), 708px"
        />
      )}
    </ShadowWrapper>

    <ContentBlock
      content={copy}
      css={{
        order: flipped ? 1 : 2,
        color: theme.colors.secondaryText,
        "& p:first-of-type": { marginTop: 0 },
        "& :last-child": { marginBottom: 0 },
        [theme.mq.smallMobile]: { order: 2 },
      }}
    />
  </div>
)

FeatureBlock.propTypes = {
  copy: PropTypes.array.isRequired,
  image: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }),
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  flipped: PropTypes.bool,
}

const MediaFeature = ({ image, video }) => (
  <ShadowPop>
    <ShadowWrapper shadow={theme.shadows.image}>
      {video ? (
        <GifVideo src={video.asset.url} css={{ width: "100%" }} />
      ) : (
        <SanityImage
          {...image}
          width={1228}
          sizes="(max-width: 1278px) calc((100vw - 50px) / 1.735), 1228px"
          alt=""
          css={{ width: "100%" }}
        />
      )}
    </ShadowWrapper>
  </ShadowPop>
)

MediaFeature.propTypes = {
  image: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }),
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export const query = graphql`
  fragment FeaturesBlock on SanityFeatures {
    title
    description
    features {
      _key
      copy: _rawCopy
      image {
        ...ImageWithPreview
      }
      video {
        asset {
          url
        }
      }
    }
  }
`
