import Layout from "components/Layout"
import Metadata from "components/Metadata"
import { graphql } from "gatsby"
import renderContentBlocks from "lib/renderContentBlocks"
import PropTypes from "prop-types"
import React from "react"

const Page = ({ data: { page }, location }) => (
  <Layout mode={page.mode} currentPath={location.pathname}>
    <Metadata {...page.metadata} />
    {renderContentBlocks(page.content)}
  </Layout>
)

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      content: PropTypes.array.isRequired,
      mode: PropTypes.string.isRequired,
      metadata: PropTypes.shape(Metadata.propTypes).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(_id: { eq: $id }) {
      mode
      content {
        ...Blocks
      }
      metadata {
        ...Metadata
      }
    }
  }
`
