import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import ShadowPop from "components/ShadowPop"

const ComparisonList = ({ titleA, itemsA, titleB, itemsB }) => (
  <ShadowPop
    css={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 22,
      background: theme.colors.extendedUI.offsetWhite,
      padding: "50px 40px",
      maxWidth: 1228,
      margin: "48px auto",
      [theme.mq.mobile]: {
        maxWidth: 600,
        margin: "40px auto",
        padding: 25,
        gridTemplateColumns: "1fr",
        gap: 20,
      },
    }}
  >
    <List title={titleA} items={itemsA} />
    <List
      title={titleB}
      items={itemsB}
      css={{
        "--listBg": theme.colors.extended.mint1,
        "--listItemBg": theme.colors.extended.mint3,
      }}
    />
  </ShadowPop>
)

ComparisonList.propTypes = {
  titleA: PropTypes.string.isRequired,
  titleB: PropTypes.string.isRequired,
  itemsA: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  itemsB: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default ComparisonList

const List = ({ title, items, className }) => (
  <div css={{ textAlign: "center" }} className={className}>
    <h3
      css={{
        ...theme.h3,
        padding: "28px 30px",
        borderRadius: 4,
        boxShadow: theme.shadows.shallow,
        background: `var(--listBg, ${theme.colors.extendedUI.lightGrey})`,
        [theme.mq.mobile]: {
          padding: 22,
          borderRadius: 2,
        },
      }}
    >
      {title}
    </h3>

    <ul
      css={{
        margin: 0,
        padding: 30,
        borderRadius: 4,
        boxShadow: theme.shadows.shallow,
        background: `var(--listBg, ${theme.colors.extendedUI.lightGrey})`,
        listStyle: "none",
        [theme.mq.mobile]: {
          borderRadius: 2,
          padding: "15px 10px",
        },
      }}
    >
      {items.map((item, index) => (
        <li
          key={index}
          css={{
            borderRadius: 4,
            boxShadow: theme.shadows.shallow,
            background: `var(--listItemBg, ${theme.colors.extendedUI.lightGrey})`,
            color: theme.colors.extendedUI.grey3,
            padding: "13px 2em 12px",
            margin: 0,
            [theme.mq.mobile]: {
              padding: "8px 1em",
              borderRadius: 2,
            },
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  </div>
)

List.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  className: PropTypes.string,
}

export const query = graphql`
  fragment ComparisonList on SanityComparisonListInfographic {
    _key
    _type
    titleA
    itemsA
    titleB
    itemsB
  }
`
