import Button from "components/Button"
import ShadowPop from "components/ShadowPop"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import React from "react"
import theme from "styles/theme"

const FeatureIconGrid = ({
  slug,
  features = [],
  ctaFooterLink = undefined,
}) => (
  <div
    id={slug}
    css={theme.expandQueries({
      padding: ["70px 50px", "50px 25px"],
      [theme.mq.range(700, 900)]: {
        padding: "85px 40px",
      },
    })}
  >
    <ShadowPop
      css={theme.expandQueries({
        padding: ["80px 16px", 20],
        maxWidth: 1228,
        background: theme.colors.highlightBackground,
      })}
    >
      <div
        css={theme.expandQueries({
          display: "grid",

          gridTemplateColumns: `repeat(${features.length}, 1fr)`,
          gap: [12, 22],
          justifyContent: "center",
          margin: "0 auto",
          marginBottom: 0,
          // [theme.mq.tablet]: {
          //   gridTemplateColumns: `repeat(3, 1fr)`,
          // },
          [theme.mq.smallMobile]: {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        })}
      >
        {features &&
          features.map(({ _key, title, subtitle, image }) => (
            <div
              key={_key}
              css={theme.expandQueries({
                display: "flex",
                flexDirection: "column",
              })}
            >
              {image && (
                <SanityImage
                  {...image}
                  width={376}
                  alt={title}
                  sizes="(max-width: 700px) 287px, (max-width: 1300px) calc((100vw - 170px) / 3), 376px"
                  css={theme.expandQueries({
                    // width: "100%",
                    width: "auto",
                    minHeight: "230px",
                    padding: "1.6rem",
                    objectFit: "contain",
                    [theme.mq.tablet]: {
                      minHeight: "150px",
                    },
                    [theme.mq.smallMobile]: {
                      display: "none",
                    },
                  })}
                />
              )}
              <div
                css={{
                  padding: "0 0.375em",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "stretch",
                  height: "100%",
                  [theme.mq.smallMobile]: { textAlign: "left" },
                }}
              >
                <h3
                  css={{
                    // fontSize: "0.9rem",
                    fontWeight: 500,
                    marginBottom: "1em",
                    // margin: "1em auto 0",
                    margin: "0 auto 0",
                    minHeight: "3rem",
                    maxWidth: title.length > 20 ? "100%" : "80%",
                    // wordBreak: "break-word",
                    [theme.mq.smallMobile]: { minHeight: "2rem" },
                  }}
                >
                  {title}
                </h3>
                {subtitle && (
                  <div
                    css={{
                      fontSize: "0.8rem",
                    }}
                  >
                    {subtitle}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      {ctaFooterLink && (
        <div
          css={{
            marginTop: 48,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            [theme.mq.mobile]: {
              marginTop: 24,
            },
          }}
        >
          <Button href={ctaFooterLink.url}>{ctaFooterLink.title}</Button>
        </div>
      )}
    </ShadowPop>
  </div>
)

export default FeatureIconGrid

export const query = graphql`
  fragment FeatureIconGridBlock on SanityFeatureIconGrid {
    features {
      _key
      title
      subtitle
      image {
        ...ImageWithPreview
      }
    }
    ctaFooterLink {
      ...Link
    }
  }
`
