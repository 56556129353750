import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import theme from "styles/theme"
import { motion } from "framer-motion"
import ContentBlock, { nodeToProps } from "components/ContentBlock"
import OpenIndicator from "components/OpenIndicator"
import InvisibleButton from "components/InvisibleButton"
import ShadowWrapper from "components/ShadowWrapper"

const Accordion = ({ title, blocks, slug }) => {
  const [openItem, setOpenItem] = useState(false)
  const close = useCallback(() => {
    setOpenItem(false)
  }, [setOpenItem])

  return (
    <section
      id={slug}
      css={{
        padding: "100px 25px",
        maxWidth: 1070,
        margin: "0 auto",
      }}
    >
      <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

      <div css={{ marginTop: 50 }}>
        {blocks.map(({ _key, title, content }) => (
          <AccordionItem
            key={_key}
            id={_key}
            isOpen={openItem === _key}
            onRequestOpen={setOpenItem}
            onRequestClose={close}
            title={title}
          >
            <ContentBlock
              content={content}
              serializers={{
                types: {
                  accordionImage: nodeToProps(AccordionImage),
                },
              }}
            />
          </AccordionItem>
        ))}
      </div>
    </section>
  )
}

export default Accordion

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

const AccordionItem = ({
  id,
  title,
  children,
  isOpen,
  onRequestOpen,
  onRequestClose,
}) => (
  <motion.div
    css={{
      borderRadius: 4,
    }}
    animate={{
      background: isOpen
        ? theme.colors.insetBackground
        : theme.colors.floatingContainerBackground,
      boxShadow: isOpen ? "none" : theme.shadows.tight,
    }}
  >
    <motion.div
      css={{ borderRadius: 4 }}
      animate={{
        boxShadow: isOpen ? theme.shadows.inset : "none",
      }}
    >
      <InvisibleButton
        css={theme.expandQueries({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          padding: [24, 12],
          textAlign: "left",
        })}
        onClick={() => {
          if (isOpen) {
            onRequestClose()
          } else {
            onRequestOpen(id)
          }
        }}
      >
        <h3 css={{ ...theme.h3, marginRight: "1em" }}>{title}</h3>
        <OpenIndicator open={isOpen} />
      </InvisibleButton>

      <motion.div
        animate={{
          height: isOpen ? "auto" : 0,
        }}
        css={{ overflow: "hidden", height: 0 }}
      >
        <div
          css={{
            ...theme.p3,
            padding: "0 25px 25px",
            maxWidth: 700,
            color: theme.colors.secondaryText,
            [theme.mq.mobile]: {
              padding: "0 12px 24px",
            },
          }}
        >
          {children}
        </div>
      </motion.div>
    </motion.div>
  </motion.div>
)

AccordionItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestOpen: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
}

const AccordionImage = (props) => (
  <ShadowWrapper shadow={theme.shadows.image} css={{ maxWidth: 700 }}>
    <SanityImage width={700} alt="" css={{ width: "100%" }} {...props} />
  </ShadowWrapper>
)

export const query = graphql`
  fragment AccordionBlock on SanityAccordion {
    title
    blocks {
      _key
      title
      content: _rawContent
    }
  }
`
