import Button from "components/Button"
import ContentBlock from "components/ContentBlock"
import ShadowPop from "components/ShadowPop"
import ShadowWrapper from "components/ShadowWrapper"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const Highlight = ({
  title,
  copy,
  richCopy,
  image,
  cta,
  slug,
  reverseOrder = undefined,
}) => {
  const styles = image ? STYLES.withImage : STYLES.standard
  const Container = image ? ShadowPop : "div"

  return (
    <div id={slug} css={styles.wrapper}>
      <Container
        css={{
          ...styles.container,
          flexDirection: reverseOrder ? "row-reverse" : "row",
        }}
      >
        <div css={styles.content}>
          <h2 css={styles.heading}>{title}</h2>

          {copy && <p css={styles.copy}>{copy}</p>}

          {!copy && richCopy && (
            <ContentBlock content={richCopy} css={styles.copy} />
          )}

          {cta && (
            <div css={styles.cta}>
              <Button href={cta.url}>{cta.title}</Button>
            </div>
          )}
        </div>

        {image && (
          <ShadowWrapper
            css={theme.expandQueries({
              flex: "0 1 50%",
              marginLeft: [20, 0],
              marginTop: [0, 8],
              position: "relative",
              overflow: "hidden",
              ":before": {
                content: "''",
                display: "block",
                paddingBottom: "82.5%",
                [theme.mq.mobile]: {
                  paddingBottom: "70.55%",
                },
              },
            })}
          >
            <SanityImage
              {...image}
              alt=""
              width={630}
              sizes="(max-width: 525px) calc(100vw - 66px), (max-width: 850px) 459px, (max-width: 1580px) calc((100vw - 180px) / 2), 700px"
              css={theme.expandQueries({
                width: "100%",
                // height: ["100%", "auto"],
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              })}
            />
          </ShadowWrapper>
        )}
      </Container>
    </div>
  )
}

export default Highlight

Highlight.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }),
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string,
  reverseOrder: PropTypes.bool,
}

const STYLES = theme.expandQueries({
  standard: {
    container: {
      padding: ["80px 30px", 30],
      background: theme.colors.highlightBackground,
    },
    content: { textAlign: "center" },
    heading: { ...theme.h2, maxWidth: "15em", margin: "0 auto" },
    copy: {
      maxWidth: "30em",
      margin: ["30px auto", "20px auto"],
      color: theme.colors.secondaryText,
    },
    cta: {
      textAlign: "center",
      marginTop: [30, 20],
    },
  },

  withImage: {
    wrapper: {
      // padding: ["85px 70px", "50px 25px"],
      padding: ["65px 70px", "50px 25px"],
      [theme.mq.range(700, 900)]: {
        padding: "85px 40px",
      },
    },
    container: {
      display: ["flex", "block"],
      padding: [10, 8],
      background: theme.colors.floatingContainerBackground,
      maxWidth: [1440, 475],
      // maxHeight: [440, "none"],
    },
    content: {
      display: "block",
      flex: "0 1 50%",
      alignSelf: "center",
      padding: ["90px 20px 90px 70px", "22px"],
      [theme.mq.range(600, 1250)]: {
        padding: "50px 22px",
      },
    },
    heading: {
      ...theme.h2,
      maxWidth: ["none", 335],
    },
    copy: {
      color: theme.colors.secondaryText,
      maxWidth: [440, 280],
      margin: ["20px 0", "10px 0"],
    },
    cta: {
      margin: ["20px 0 0", "10px 0 0"],
    },
  },
})

export const query = graphql`
  fragment HighlightBlock on SanityHighlight {
    title
    copy
    richCopy: _rawRichCopy(resolveReferences: { maxDepth: 6 })
    image {
      ...ImageWithPreview
    }
    cta {
      ...Link
    }
    reverseOrder
  }
`
