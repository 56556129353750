import React from "react"
import contentBlocks from "components/blocks"

export default (blockData, extraProps = {}) =>
  blockData
    .filter(Boolean)
    .map(({ __typename, _type = "", slug, ...props }) => {
      const type = _type.slice(0, 1).toUpperCase() + _type.slice(1)

      if (contentBlocks[type]) {
        return React.createElement(contentBlocks[type], {
          key: props._key,
          slug: slug?.current,
          ...extraProps,
          ...props,
        })
      } else {
        if (process.env.NODE_ENV !== "production")
          console.warn(`No connector found for ${_type || __typename}`)
        return null
      }
    })
