// This is a generated file. To regenerate it, run `yarn index`.
import { graphql } from "gatsby"
import Accordion from "./Accordion"
import AnimatedCards from "./AnimatedCards"
import ArticleCollection from "./ArticleCollection"
import ArticleHero from "./ArticleHero"
import BigImage from "./BigImage"
import BigImages from "./BigImages"
import CardList from "./CardList"
import CaseStudies from "./CaseStudies"
import ExpansionCard from "./ExpansionCard"
import FeatureIconGrid from "./FeatureIconGrid"
import Features from "./Features"
import Highlight from "./Highlight"
import HighlightCarousel from "./HighlightCarousel"
import Infographic from "./Infographic"
import InlineVideo from "./InlineVideo"
import IntroText from "./IntroText"
import JobListings from "./JobListings"
import LogoSalad from "./LogoSalad"
import NewsletterSignup from "./NewsletterSignup"
import PeopleGrid from "./PeopleGrid"
import QuickInfo from "./QuickInfo"
import Quote from "./Quote"
import ReadMore from "./ReadMore"
import RelatedArticles from "./RelatedArticles"
import ResearchPapersCollection from "./ResearchPapersCollection"
import SimpleHero from "./SimpleHero"
import SimpleText from "./SimpleText"
import SmallImage from "./SmallImage"
import Stats from "./Stats"
import TabbedTitle from "./TabbedTitle"
import TextColumns from "./TextColumns"
import TextHero from "./TextHero"
import Trio from "./Trio"

export default {
  Accordion: Accordion,
  AnimatedCards: AnimatedCards,
  ArticleCollection: ArticleCollection,
  ArticleHero: ArticleHero,
  BigImage: BigImage,
  BigImages: BigImages,
  CardList: CardList,
  CaseStudies: CaseStudies,
  ExpansionCard: ExpansionCard,
  FeatureIconGrid: FeatureIconGrid,
  Features: Features,
  Highlight: Highlight,
  HighlightCarousel: HighlightCarousel,
  Infographic: Infographic,
  InlineVideo: InlineVideo,
  IntroText: IntroText,
  JobListings: JobListings,
  LogoSalad: LogoSalad,
  NewsletterSignup: NewsletterSignup,
  PeopleGrid: PeopleGrid,
  QuickInfo: QuickInfo,
  Quote: Quote,
  ReadMore: ReadMore,
  RelatedArticles: RelatedArticles,
  ResearchPapersCollection: ResearchPapersCollection,
  SimpleHero: SimpleHero,
  SimpleText: SimpleText,
  SmallImage: SmallImage,
  Stats: Stats,
  TabbedTitle: TabbedTitle,
  TextColumns: TextColumns,
  TextHero: TextHero,
  Trio: Trio,
}

export const query = graphql`
  fragment Blocks on ContentBlock {
    ...ContentBlockBase

    # Block fragments
    ...AccordionBlock
    ...AnimatedCardsBlock
    ...ArticleCollectionBlock
    ...ArticleHeroBlock
    ...BigImageBlock
    ...BigImagesBlock
    ...CardListBlock
    ...CaseStudiesBlock
    ...ExpansionCardBlock
    ...FeatureIconGridBlock
    ...FeaturesBlock
    ...HighlightBlock
    ...HighlightCarouselBlock
    ...InfographicBlock
    ...InlineVideoBlock
    ...IntroTextBlock
    ...JobListingsBlock
    ...LogoSaladBlock
    ...NewsletterSignupBlock
    ...PeopleGridBlock
    ...QuickInfoBlock
    ...QuoteBlock
    ...ReadMoreBlock
    ...RelatedArticlesBlock
    ...ResearchPapersCollectionBlock
    ...SimpleHeroBlock
    ...SimpleTextBlock
    ...SmallImageBlock
    ...StatsBlock
    ...TabbedTitleBlock
    ...TextColumnsBlock
    ...TextHeroBlock
    ...TrioBlock
  }
`
