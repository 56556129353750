import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import theme from "styles/theme"

const Trio = ({ tiles, slug }) => (
  <div
    id={slug}
    css={{ padding: "1.6em", [theme.mq.mobile]: { padding: "30px 25px" } }}
  >
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: 20,
        justifyContent: "center",
        maxWidth: 1228,
        margin: "0 auto",
        [theme.mq.max(700)]: {
          gridTemplateColumns: "1fr",
          maxWidth: 300,
        },
      }}
    >
      {tiles.map(({ _key, title, subtitle, image }) => (
        <div
          key={_key}
          css={{
            padding: "0.45em",
            background: theme.colors.floatingContainerBackground,
            boxShadow: theme.shadows.tight,
            borderRadius: "0.2em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div css={{ ...theme.h3, padding: "0.375em", marginBottom: "1em" }}>
            <h3 css={{ fontSize: "inherit", fontWeight: 500 }}>{title}</h3>
            <div css={theme.h3}>{subtitle}</div>
          </div>
          <SanityImage
            {...image}
            width={376}
            alt=""
            sizes="(max-width: 700px) 287px, (max-width: 1300px) calc((100vw - 170px) / 3), 376px"
            css={{ width: "100%" }}
          />
        </div>
      ))}
    </div>
  </div>
)

export default Trio

Trio.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      image: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  slug: PropTypes.string,
}

export const query = graphql`
  fragment TrioBlock on SanityTrio {
    tiles {
      _key
      title
      subtitle
      image {
        ...ImageWithPreview
      }
    }
  }
`
