import Button from "components/Button"
import ContentBlock from "components/ContentBlock"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const Stats = ({ title, richCopy, stats, cta, slug }) => (
  <div
    id={slug}
    css={theme.expandQueries({ padding: ["48px 25px", "30px 25px"] })}
  >
    <div
      css={theme.expandQueries({
        // maxWidth: "36em",
        // maxWidth: "48em",
        maxWidth: stats.length <= 2 ? "36rem" : "48em",
        margin: "0 auto",
      })}
    >
      {title && <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>}

      {richCopy && (
        <ContentBlock
          content={richCopy}
          css={theme.expandQueries({
            marginTop: [30, 20],
          })}
        />
      )}

      <div
        css={theme.expandQueries({
          display: "grid",
          gridTemplateColumns: `repeat(${stats.length}, 1fr)`,
          gap: [20, 15],
          justifyContent: "center",
          margin: "30px auto",
          [theme.mq.smallMobile]: {
            gridTemplateColumns: 250,
          },
        })}
      >
        {stats.map(({ _key, ...statProps }) => (
          <Stat key={_key} {...statProps} />
        ))}
      </div>

      {cta && (
        <div
          css={theme.expandQueries({
            textAlign: "center",
            marginTop: [30, 20],
          })}
        >
          <Button href={cta.url}>{cta.title}</Button>
        </div>
      )}
    </div>
  </div>
)

export default Stats

Stats.propTypes = {
  title: PropTypes.string.isRequired,
  richCopy: PropTypes.array,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  slug: PropTypes.string,
}

const Stat = ({ value, label }) => (
  <div
    css={theme.expandQueries({
      // padding: "0.45em",
      padding: "0.55em",
      background: theme.colors.floatingContainerBackground,
      boxShadow: theme.shadows.tight,
      borderRadius: "0.2em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      textAlign: "center",
    })}
  >
    <div
      css={{
        ...theme.h2,
        display: "flex",
        // flex: "1 0 auto",
        flex: "1",
        color: theme.colors.accentColor,
        fontWeight: 500,
        minHeight: "3rem",
      }}
    >
      {value}
    </div>
    <div
      css={{
        ...theme.p3,
        color: theme.colors.secondaryText,
        flex: "1",
        padding: "0.55em",
      }}
    >
      {label}
    </div>
  </div>
)

Stat.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export const query = graphql`
  fragment StatsBlock on SanityStats {
    title
    richCopy: _rawCopy
    stats {
      _key
      value
      label
    }
    cta {
      ...Link
    }
  }
`
